// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.navbar {
    background-color: #1b2130; 
    display: flex;
    flex: 1 1;
    height: 150px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.navbar h1{
    font-size: 3em;
    color: #DEB992;
}

.navbar img {
    padding: 10px;
    height: 100%;
    width: auto;
}

.navbar-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #DEB992;
    font-size: 1.5em;
    padding: 0px 10px;
}

.navbar-profile-inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.navbar-profile img{
    height: 90px;
    border-radius: 25px;
}

  

  
  `, "",{"version":3,"sources":["webpack://./src/Styles/Navbar.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,aAAa;IACb,SAAO;IACP,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["\n.navbar {\n    background-color: #1b2130; \n    display: flex;\n    flex: 1;\n    height: 150px;\n    width: 100%;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.navbar h1{\n    font-size: 3em;\n    color: #DEB992;\n}\n\n.navbar img {\n    padding: 10px;\n    height: 100%;\n    width: auto;\n}\n\n.navbar-profile {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    color: #DEB992;\n    font-size: 1.5em;\n    padding: 0px 10px;\n}\n\n.navbar-profile-inner{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n}\n\n.navbar-profile img{\n    height: 90px;\n    border-radius: 25px;\n}\n\n  \n\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
