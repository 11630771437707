// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ToggleSwitch.css */
.toggle-switch {
    display: flex;
    border-radius: 20px; /* Rounded corners for the toggle switch background */
    background-color: #151a1f; /* Dark background for the toggle switch */
    padding: 5px; /* Padding inside the toggle switch */
  }
  
  .toggle-option {
    background-color: transparent; /* No background for individual options */
    color: #DEB992;
    border: none; /* No border */
    padding: 10px 20px; /* Padding inside buttons */
    margin: 0; /* No margin between buttons */
    border-radius: 15px; /* Rounded corners for buttons */
    cursor: pointer;
    outline: none; /* Removes the outline */
    transition: background-color 0.3s, opacity 0.3s; /* Smooth transition for background color and opacity */
    font-size: 16px; /* Match the font size to your design */
  }
  
  .toggle-option.selected {
    background-color: #1BA098; /* Highlighted background for selected option */
    color: #DEB992; /* White text for selected option */
    border-radius: 15px; /* Ensure rounded corners for the selected option */
    opacity: 1; /* Full opacity for selected option */
  }
  
  .toggle-option:not(.selected):hover {
    opacity: 0.7; /* Slightly transparent on hover for unselected options */
  }
  `, "",{"version":3,"sources":["webpack://./src/Styles/ToggleSwitch.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,aAAa;IACb,mBAAmB,EAAE,qDAAqD;IAC1E,yBAAyB,EAAE,0CAA0C;IACrE,YAAY,EAAE,qCAAqC;EACrD;;EAEA;IACE,6BAA6B,EAAE,yCAAyC;IACxE,cAAc;IACd,YAAY,EAAE,cAAc;IAC5B,kBAAkB,EAAE,2BAA2B;IAC/C,SAAS,EAAE,8BAA8B;IACzC,mBAAmB,EAAE,gCAAgC;IACrD,eAAe;IACf,aAAa,EAAE,wBAAwB;IACvC,+CAA+C,EAAE,uDAAuD;IACxG,eAAe,EAAE,uCAAuC;EAC1D;;EAEA;IACE,yBAAyB,EAAE,+CAA+C;IAC1E,cAAc,EAAE,mCAAmC;IACnD,mBAAmB,EAAE,mDAAmD;IACxE,UAAU,EAAE,qCAAqC;EACnD;;EAEA;IACE,YAAY,EAAE,yDAAyD;EACzE","sourcesContent":["/* ToggleSwitch.css */\n.toggle-switch {\n    display: flex;\n    border-radius: 20px; /* Rounded corners for the toggle switch background */\n    background-color: #151a1f; /* Dark background for the toggle switch */\n    padding: 5px; /* Padding inside the toggle switch */\n  }\n  \n  .toggle-option {\n    background-color: transparent; /* No background for individual options */\n    color: #DEB992;\n    border: none; /* No border */\n    padding: 10px 20px; /* Padding inside buttons */\n    margin: 0; /* No margin between buttons */\n    border-radius: 15px; /* Rounded corners for buttons */\n    cursor: pointer;\n    outline: none; /* Removes the outline */\n    transition: background-color 0.3s, opacity 0.3s; /* Smooth transition for background color and opacity */\n    font-size: 16px; /* Match the font size to your design */\n  }\n  \n  .toggle-option.selected {\n    background-color: #1BA098; /* Highlighted background for selected option */\n    color: #DEB992; /* White text for selected option */\n    border-radius: 15px; /* Ensure rounded corners for the selected option */\n    opacity: 1; /* Full opacity for selected option */\n  }\n  \n  .toggle-option:not(.selected):hover {\n    opacity: 0.7; /* Slightly transparent on hover for unselected options */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
